.discipline {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.discipline:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.age-category {
  font-weight: bold;
  margin-bottom: 10px;
  color: #2c5282;
  font-size: 0.9em;
  text-transform: uppercase;
  border-bottom: 1px solid #2c5282;
  padding-bottom: 4px;
  cursor: pointer;
}
.result-table {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.result-card {
  background-color: #f0f4f8;
  border-radius: 6px;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #cbd5e0;
  font-size: 0.85em;
  display: flex;
  flex-direction: column;
  position: relative;
}
.result-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}
.result-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.result-item h3 {
  margin: 0;
  font-size: 0.9em;
  color: #2c5282;
  font-weight: 600;
  flex-grow: 1;
}
.result-item p {
  margin: 0;
  font-size: 0.75em;
  color: #4a5568;
}
.place-badge {
  background-color: #ffffff;
  color: #2c5282;
  font-weight: bold;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 0.9em;
  flex-shrink: 0;
  border: 2px solid #2c5282;
}
.stats {
  margin-top: 6px;
  font-size: 0.75em;
  color: #2d3748;
  display: flex;
  justify-content: space-between;
  background-color: #e2e8f0;
  padding: 5px;
  border-radius: 6px;
}
.stats span {
  font-weight: 500;
  display: flex;
  align-items: center;
}
.stats i {
  margin-right: 4px;
  color: #2c5282;
}
.result-card .download-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1em;
  color: #2c5282;
  cursor: pointer;
  transition: color 0.3s ease;
}
.result-card .download-icon:hover {
  color: gold;
}

.champion-place,
.champion-place-second,
.champion-place-third {
  position: absolute;
  top: 50%;
  left: -52px;
  transform: translateY(-50%) rotate(-90deg);
  background-color: #fad201;
  padding: 5px 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.9em;
  width: 75px;
  text-align: center;
}

.champion-place-second {
  background-color: silver;
}

.champion-place-third {
  background-color: #cd7f32;
}

.champion {
  border-left: 5px solid #fad201;
  box-shadow: 5px 0 10px -5px gold;
  position: relative; /* Чтобы псевдоэлемент был относительно этой карточки */
}

.second-place {
  border-left: 5px solid silver;
  box-shadow: 5px 0 10px -5px silver;
  position: relative; /* Чтобы псевдоэлемент был относительно этой карточки */
}

.third-place {
  border-left: 5px solid #cd7f32;
  box-shadow: 5px 0 10px -5px #cd7f32;
  position: relative; /* Чтобы псевдоэлемент был относительно этой карточки */
}
