.teamCard {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #34495e; /* Синяя обводка */
  flex-wrap: wrap;
  transition: transform 0.3s;
}

.teamCard:hover {
  transform: scale(1.02);
}

.teamInfo {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.placeCircle {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  background-color: #34495e; /* Default color */
}

.placeCircle.gold {
  background-color: gold;
}

.placeCircle.silver {
  background-color: silver;
}

.placeCircle.bronze {
  background-color: #cd7f32; /* Бронзовый цвет */
}

.teamInfo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #34495e; /* Синяя обводка */
}

.teamDetails {
  display: flex;
  flex-direction: column;
}

.teamName {
  font-size: 1.2rem;
  font-weight: bold;
  color: #34495e; /* Темно-синий текст */
}

.teamLocation {
  font-size: 0.9rem;
  color: #555;
}

.teamStatsButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.teamStats {
  display: flex;
  gap: 10px;
  align-items: center;
}

.teamStats div {
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  font-size: 1.2rem;
  color: #34495e; /* Темно-синий текст */
  cursor: pointer;
  transition: color 0.3s;
}

.teamStats div:hover {
  color: #2c3e50;
}

.teamStats div i {
  margin-right: 5px;
}

.win i {
  color: green;
}

.loss i {
  color: red;
}

.loss i::before {
  content: "✖"; /* Красный крестик для поражений */
}

.tooltip {
  visibility: hidden;
  background-color: #34495e; /* Мягкий темно-синий цвет */
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.teamStats div:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.buttons {
  display: flex;
  gap: 10px;
  align-items: center; /* Выровнены с иконками */
}

.participantsButton,
.downloadButton,
.diplomaButton {
  padding: 10px 15px;
  background-color: white;
  border: 2px solid #34495e;
  color: #34495e;
  border-radius: 30px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.disableBut {
  padding: 10px 15px;
  background-color: white;
  border: 2px solid #949494;
  color: #949494;
  border-radius: 30px;
  font-size: 0.9rem;
}
.participantsButton:hover,
.downloadButton:hover,
.diplomaButton:hover {
  background-color: #34495e;
  color: white;
}

.participantsButton {
  &.active {
    background-color: #34495e;
    color: white;
  }
}

/* Добавьте здесь любые другие стили, если необходимо */
.ParticipantsList {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
}
