.places
    display: flex
    flex-direction: column
    align-items: start
    & h4 
        margin-bottom: 10px
    &__table
        & table
            width: auto
       
    & td, th
        font-size: 15px
        border: 1px solid rgba(255,255,255,0.3)
        padding: 3px 8px
        color: black
        border: 1px solid black
    & th
        font-weight: bold