.wrapper
    display: flex
    width: 100%

.searchLine
    width: 100%

button.button
    width: auto
    margin-left: 16px
    font-size: 10px

@media screen and (min-width: 768px)
    button.button
        font-size: 12px
