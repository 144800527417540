.discount
    width: auto
    overflow: auto

.table 
    border-radius: 0
    width: auto !important
    & td, th
        font-size: 16px
        border: 1px solid rgba(255,255,255,0.3)
        padding: 8px 10px
        color: white
    & th 
        font-weight: bold
    &__discipline
        min-width: 70px
        max-width: 70px
    &__disciplinesHeader
        // width: 100%
    &__date
        min-width: 140px
        max-width: 140px
    &__datesHeader
        max-width: 280px !important
    &__price
        min-width: 100px
        max-width: 100px
    &__actions
        display: flex
        gap: 10px
        justify-content: center