.addCode
    display: flex
    flex-direction: column
    gap: 12px
    align-items: start

.text
    width: 100%

.select
    width: auto