.register
    margin: 0 auto
    max-width: 360px
    
.wrapper
    padding: 16px

.form
    display: grid
    gap: 16px
    & > *
        width: 100%

.phone
    display: grid
    grid-template-columns: 20fr 80fr
    gap: 8px