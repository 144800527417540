.club 
    display: grid
    gap: 16px

.searchbar
    display: grid
    grid-template-columns: 1fr auto
    gap: 8px

.list
    margin-top: 8px
    display: grid
    gap: 8px