.btn {
  border-radius: 20px !important;
  color: #1a1f36 !important;
  border: none !important;
  font-weight: bold !important;
  height: 30px !important;
  line-height: 10px !important;
}
.filterButton {
  flex-grow: 1 !important;
  &:hover {
    background-color: #828dbd !important;
  }
}

.activeButton {
  color: white !important;
  background-color: #1a1f36 !important;
  border: 1px solid #1a1f36 !important;
  padding: 0 15px !important;
}

.buttonGroup {
  display: flex;
  width: 700px;
  border: 1px solid #1a1f36;
  padding: 3px;
  gap: 5px;
}
.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}
.date,
.textField {
  border: 1px solid #eeee; /* Задаем границу */
  border-radius: 20px; /* Скругляем углы */
  height: 33px;
  padding: 0px 7px;
  background-color: #fafafa; /* Цвет фона */
  box-shadow: none; /* Убираем углубление */
  outline: none; /* Убираем контур */
  width: 150px; /* Чтобы input занимал всю ширину блока */
  &::placeholder {
    color: black;
  }
}

.date:focus {
  border-color: #bbbbbb; /* Цвет границы при фокусе */
  outline: none; /* Убираем стандартный фокус */
}

.textField {
  flex-grow: 1;
}

.hideFilter {
  border: 2px solid #1a1f36;
  background: #ebebebde;
  border-radius: 10px;
  padding: 10px 5px 20px;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
