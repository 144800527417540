.documentsPolicyContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.documents,
.policy {
  width: 28%;
  padding: 10px;
}

.documents a {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  color: #003366;
  text-decoration: none;
  transition: color 0.3s;
}
.title {
  margin: 20px 0;
}
.documents a:hover {
  color: red;
}

.policy p {
  font-size: 18px;
  margin: 5px 0;
}

.mapContainer {
  width: 40%;
  padding: 10px;
  text-align: center;
}

.mapContainer iframe {
  width: 100%;
  height: 250px;
  border: none;
}
