.wrapper
    display: flex
    flex-direction: column
    width: 300px
    & li
        display: flex
        justify-content: center
        height: 40px

@media screen and (min-width: 768px)
    .wrapper
        flex-direction: row
        width: auto
        margin-left: 34px

