.admin    
    flex: 1 1 auto
    margin: 70px 24px 16px 24px
    
.wrapper
    max-width: 1800px
    margin: 0 auto
    height: 100%

.title
    margin: 24px 0

.addComp
    width: 100%
    padding: 16px
    max-width: 480px


.form
    display: grid
    gap: 16px
