.logoWrapper
    display: flex
    align-items: center
    

.logo
    border-radius: 15px
    margin-right: 16px
    object-fit: cover
    width: 80px
    height: 80px
