// Переменные
$primary-color: #1f3b4d;
$secondary-color: #f8f9fa;
$text-color: #333;
$border-color: #e9ecef;
$shadow-color: rgba(0, 0, 0, 0.08);
$hover-shadow: rgba(0, 0, 0, 0.3);
$gray-color: #9ca3af;
$dark-gray-color: #6b7280;
$white-color: #fff;
$gold-color: rgba(255, 215, 0, 0.5);
$silver-color: rgba(192, 192, 192, 0.5);
$bronze-color: rgba(205, 127, 50, 0.5);
.wrapperContainet {
  position: relative;
  width: 100%;
  height: 100%;
}
// Шапка
.header {
  background-color: $primary-color;
  color: $white-color;
  padding: 10px 0;
  border-radius: 3px;
  text-align: center;
  box-shadow: none;

  h1 {
    margin: 0;
    font-size: 32px;
  }

  p {
    margin: 5px 0 0;
    font-size: 10px;
  }
}
.timeCell {
  text-align: center;
}

// Контейнер главного содержимого
.container {
  width: 95%;
  margin: 30px auto;
  max-width: 1400px;
  padding: 20px;
  background-color: $white-color;
  box-shadow: 0 2px 6px $shadow-color;
  border-radius: 6px;
}

// Подвал
.footer {
  text-align: center;
  padding: 20px 0;
  background-color: $primary-color;
  color: $white-color;
  font-size: 14px;

  p {
    margin: 5px 0;
  }
}

// Блок настроек времени и счетчики медалей
.timeSettings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}

.timeSetting {
  display: flex;
  align-items: center;
  gap: 12px;
  background: $white-color;
  padding: 5px 20px;
  border-radius: 6px;
  border: 1px solid $border-color;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  // i {
  //   color: $primary-color;
  //   font-size: 18px;
  // }

  // label {
  //   color: #495057;
  //   font-size: 14px;
  //   font-weight: 500;
  //   white-space: nowrap;
  // }

  // input {
  //   border: 1px solid $border-color;
  //   padding: 6px 10px;
  //   border-radius: 4px;
  //   font-size: 14px;
  //   color: $primary-color;
  //   background: $secondary-color;

  //   &.number {
  //     width: 40px;
  //     padding: 5px;
  //     border-radius: 4px;
  //     text-align: center;
  //   }
  // }
}
.timePicker {
  width: 130px;
}

.categoryWrapper {
  width: 100%;
}
.timeSetting {
  display: flex;
  align-items: center; /* Выравнивание по вертикали */
  gap: 8px; /* Промежуток между элементами */
}

// .inputField {
//   /* Устанавливаем одинаковую высоту для обоих полей */
//   height: 60px; /* Например, 40px, подберите по необходимости */
// }

.intervalInput {
  width: 100px; /* Уже задано в inline-стиле, можно перенести сюда */
}
.inputField .MuiInputBase-root {
  padding: 8px; /* Пример настройки внутренних отступов */
}

.inputField .MuiInputLabel-root {
  font-size: 0.875rem; /* Пример настройки размера шрифта */
}
// Медали
.medalsCounter {
  display: flex;
  align-items: center;
  gap: 15px;
  background: $white-color;
  padding: 12px 20px;
  border-radius: 8px;
  border: 1px solid $border-color;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  h3 {
    margin: 0;
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
  }

  .medalCount {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .placeNumber {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary-color;
    color: $white-color;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
  }

  .medalCountSpan {
    font-size: 14px;
    font-weight: 600;
    color: $primary-color;
  }
}

// Таблица
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
  input,
  button {
    cursor: pointer;
  }
  th,
  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.2;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  th {
    background-color: $primary-color;
    color: $white-color;
    font-size: 18px;
    text-align: center;
  }

  .timeCol {
    width: 80px;
  }

  tr:hover {
    transform: translateY(-1px);
    box-shadow: 0 8px 16px $hover-shadow, 0 4px 8px $hover-shadow;
    z-index: 2;
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1),
      box-shadow 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  }

  tr td {
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1),
      box-shadow 0.8s cubic-bezier(0.16, 1, 0.3, 1),
      background-color 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  }

  tr:hover td {
    position: relative;
  }

  .markedCategory {
    background-color: #d1d4e7 !important;
    color: #0f5132 !important;
  }

  .placeInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
    position: relative;
    margin-left: 0;
  }
  .rowControls {
    display: inline-flex;
    gap: 0;
    margin-right: 6px;
    vertical-align: middle;
    pointer-events: auto;
    border: 1px solid #e9ecef;
    border-radius: 3px;
    overflow: hidden;
    background: #fff;
    height: 16px;
  }
  .moveBtn {
    width: 20px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    color: #1e3a8a;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 0;
  }
  .fio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    font-size: 14px;
    font-weight: 900;
    color: #333;
    margin: 0;
    padding: 0;
    width: 100%;
    pointer-events: none;
    padding-left: 15px;
    .medalChecked,
    .medalCrossed {
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  .medalChecked {
    color: #1e3a8a;
    font-size: 14px;
  }
  .medalCrossed {
    color: #9ca3af;
    font-size: 14px;
  }
  .details {
    display: block;
    font-size: 12px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 2px;
    pointer-events: none;
    text-align: left;
  }
  .timeDisplay {
    font-size: 14px;
  }
}
.wrapperButtonscheckbox {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}
// Чекбоксы
.checkboxContainer {
  display: flex;
  align-items: center;
  width: 55px;
  height: 20px;
  position: relative;

  margin: 0;
  pointer-events: none;

  .alignLeft {
    align-items: flex-start;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 14px;
    height: 14px;
    margin: 0;
    z-index: 3;
    cursor: pointer;
    pointer-events: auto;
  }
  .inputChecmark {
    left: 0;
    top: 2px;
  }
  .inputCrossMark {
    left: 20px;
    top: 2px;
  }
  .checkmark,
  .crossMark {
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    pointer-events: auto;
    cursor: pointer;
    z-index: 2;
    border: 1.5px solid $gray-color;
    background-color: $white-color;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkmark {
    left: 0;
    top: 1px;
  }

  .crossMark {
    left: 20px;
    top: 1px;
  }

  .checkmark::before,
  .crossMark::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 10px;
    color: $gray-color;
    transition: all 0.2s ease;
  }

  .checkmark::before {
    content: "\f00c";
  }

  .crossMark::before {
    content: "\f00d";
  }

  &:hover {
    .checkmark,
    .crossMark {
      background-color: $secondary-color;
      border-color: $dark-gray-color;
    }

    .checkmark::before,
    .crossMark::before {
      color: $dark-gray-color;
    }
  }

  input:checked ~ .checkmark {
    background-color: $primary-color;
    border-color: $primary-color;

    &::before {
      color: $white-color;
    }
  }

  .crossMarkActive {
    background-color: $dark-gray-color !important;
    border-color: $dark-gray-color !important;

    &::before {
      color: $white-color !important;
    }
  }
}
.wrapperCheckmarkCrossed {
  width: 50px;
  display: block;
  position: relative;
}
.wrapperInfo {
  display: flex;
}
// Название категории
.categoryLabel {
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none;

  &::before {
    content: "";
  }

  &.categoryChecked::before {
    content: "\f5a2";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: $primary-color;
    margin-right: 5px;
  }

  &.grayMedal::before {
    content: "\f5a2";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 14px;
    color: $gray-color !important;
    margin-right: 8px;
  }
}

// Цветные ячейки для мест
.gold {
  background-color: $gold-color;
}

.silver {
  background-color: $silver-color;
}

.bronze {
  background-color: $bronze-color;
}

// Остальные стили (например, для кнопок и подсказок) можно продолжить аналогичным образом.
.editable {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.editable i {
  font-size: 16px;
  cursor: pointer;
  color: #1e3a78;
  transition: filter 0.2s ease;
  position: relative;
}

.editable i:hover {
  color: #1e3a78;
  filter: brightness(1.3);
}

/* Tooltip for displaying comments */
.editable i[data-tooltip] {
  position: relative;
}

.editable i[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal {
  position: fixed;
  top: 45%;
  left: 57%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  padding: 24px;
  width: 380px;
  border-radius: 20px;
  z-index: 4;
}

.textarea {
  width: 100%;
  height: 100px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;
}

.controlsRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.iconPreview,
.colorPreview {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iconPreview i {
  font-size: 20px;
  margin-right: 8px;
}

.colorBox {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
}

/* Icon and Color Grid Styles */
.iconGrid,
.colorGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  margin-top: 10px;
}

.iconGrid i,
.colorGrid .colorOption {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: transform 0.2s ease, border-color 0.2s ease;
}

.iconGrid i:hover,
.colorGrid .colorOption:hover {
  transform: scale(1.1);
  border-color: #1e3a78;
}

.colorOption {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.selectedColor {
  border-color: #1e3a78;
  box-shadow: 0 0 5px rgba(30, 58, 120, 0.5);
}

.buttonGroup {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.primary {
  background: #1e3a78;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.secondary {
  background: #f0f2f5;
  color: #666;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.primary:hover {
  background: #162a5a;
}

.secondary:hover {
  background: #e0e0e0;
}

/* Icon and Color Preview Styles */
.iconPreview.active ~ .colorGrid {
  display: none;
}

.colorPreview.active ~ .iconGrid {
  display: none;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 3;
}
