@use '../../globals'
    
    
.field
    &_mini
        width: 80px

.modal
    width: calc(100% - 32px) !important
    max-width: 1200px !important


.weights
    display: flex
    flex-direction: column
    min-width: 960px
    &__row
        display: flex
        align-items: center
        gap: 8px
    &__cell
        display: flex
        align-items: center
        flex-direction: row

.genders
    display: grid
    gap: 20px

.row
    display: flex
    align-items: center

.ageBlock
    padding: 8px 8px 8px 8px
    width: 80px
    border-radius: 15px
    background-color: globals.$semiBlack
    display: flex
    align-items: center
    justify-content: space-evenly
    margin-right: 24px
    justify-self: start
    &__age
        font-weight: bold
        background-color: globals.$semiBlack
        padding: 8px
        border-radius: 8px

.loadingWrapper
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    padding: 85px 0

.masters
    display: flex
    flex-wrap: wrap
    gap: 40px
    margin-bottom: 40px
