.competitions
    padding: 16px
    flex: 1 1 auto
    width: 100%
    display: flex
    justify-content: center

.wrapper
    display: flex
    flex-direction: column
    margin: 0 auto
    margin-top: 10px
    width: 100%

@media screen and (min-width: 768px)
    .competitions
        padding: 24px
    .wrapper
        flex-direction: row
        justify-content: stretch
        align-items: start
       
