.participantsList {
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px; /* Added padding for better spacing */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.participantsHeader {
  display: flex;
  justify-content: space-between;
  background-color: #e0e6ed;
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: bold;
}
.participantsHeader:hover {
  background-color: #dcdcdc;
}
.sortArrow {
  flex: 1;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: color 0.3s;
}

.sortArrow:hover {
  color: #34495e; /* Change color on hover */
}

.participantCard {
  display: flex;
  align-items: center;

  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);

  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.participantCard:hover {
  background-color: #e0e6ed; /* Change background on hover */
}

.participantCard div {
  flex: 1;
  text-align: center;
}

.pdfIcon {
  flex: 0 !important;
  cursor: pointer;
  color: #34495e;
  margin-right: 10px;
}

.pdfIcon:hover {
  color: #2c3e50; /* Change color on hover */
}

/* Sort colors for medal positions */
.first-place {
  color: gold;
}

.second-place {
  color: silver;
}

.third-place {
  color: #cd7f32;
}

/* Styles for wins and losses */
.win {
  color: green;
}

.loss {
  color: red;
}

.sort-active {
  font-weight: bold;
}

@media (max-width: 768px) {
  .participantsHeader {
    flex-direction: column;
  }
  .participantCard {
    flex-direction: column;
    align-items: flex-start;
  }
}
