
.avatar
    padding: 0px
    display: flex
    flex-direction: column
    max-width: 88px
    &__image
        border-radius: 15px
        object-fit: cover
        height: 88px
        width: 88px
        &_big
            width: 100%
    &__default
        border-radius: 15px
        height: 88px
        width: 88px
        background-color: #494949
        display: flex
        align-items: center
        justify-content: center

@media screen and (min-width: 600px)
    .avatar
        &__image
            height: 100px
            width: 100px
            &_big
                margin: 0 auto
                margin-bottom: 16px
                height: 200px
                width: 150px
        &__default
            &_big
                width: 100%

@media screen and (min-width: 768px)
    .avatar
        padding: 16px
        max-width: 1000px
        &__image
            height: 200px
            width: 150px
            &_big
                width: 100%
        &__default
            height: 200px
            width: 150px
            &_big
                width: 100%
