.addressUl
    display: flex
    flex-direction: column
    position: absolute
    background-color: #303030
    z-index: 100
    border-radius: 15px
    width: 100%
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)
    padding: 12px 0
    & button 

.addressInput
    border: none 
    height: auto
    width: 100%

.addressButton
    background-color: rgba(0,0,0,0)
    color: white
    padding: 8px 16px !important
    font-size: 1rem !important
    &:hover 
        background-color: rgba(0, 0, 0, 0.04) !important

.addressHighlight
    color: #fad744 !important