.clubs 
    display: flex
    flex-direction: column
    width: 100%
    margin: 0 auto

.loadingButtonWrapper
    padding: 16px
    display: flex
    justify-content: center


.actions
    display: flex
    margin: 0 0 16px auto

@media screen and (min-width: 720px)
    .modal
        width: 672px

@media screen and (min-width: 1080px)
    .modal
        width: 900px