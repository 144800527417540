.informationPage
    padding: 16px
    flex: 1 1 auto
    width: 100%
    display: flex
    justify-content: center

.wrapper
    padding: 24px
    margin: 0 auto
    margin-top: 70px
    width: 100%
    max-width: 1080px
    & p
        font-size: 18px
        & h3 
            display: block
            text-align: center
        & b
            margin-top: 20px
            display: block
            text-align: center
    & a
        color: #fad744
        text-decoration: none
    