@use '../../globals'

.wrapper
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    overflow-x: auto
    & h3
        margin-bottom: 16px

.subinfo
    display: flex
    flex-direction: column
    margin-bottom: 16px
.settingPlatformAndStage
    display: flex
    justify-content: space-between
    margin-bottom: 10px

.customSelectSetting
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: 10px



.counts
    display: flex
    flex-direction: column
    align-items: end
    font-size: 12px
    &__number
        color: var(--yellow-color)
        font-size: 14px
        font-weight: bold
    &__places
        display: flex
        align-items: center
        justify-content: end
        & span
            display: flex
            align-items: center
            margin-left: 16px

.grids
    display: flex
    flex-direction: column
    & > div
        padding: 8px
        margin-bottom: 8px
        display: flex
    &__name
        color: rgba(0, 0, 0, 0.7)
        text-decoration: none
        font-weight: bold
        cursor: pointer
    &__name:hover
        color: var(--yellow-color)
    & > div:nth-child(odd)
        background-color: rgba(0, 0, 0, 0.3)
    &__info
        display: flex
        gap: 20px
        align-items: center
    &__platformStage
        display: flex
        color: var(--yellow-color)
        gap: 10px    
    &__item
        display: flex
        justify-content: space-between
        align-items: center
        gap: 20px
    &__count
        margin-left: 30px
        display: flex
        align-items: center
        & span
            margin-left: 8px

.filter
    width: 100%
    &_mobile
    &_desktop
        display: none
        margin-right: 16px
        max-width: 240px
        position: 'relative'

.gridsWrapper
    display: flex
    flex-direction: column
    width: 100%
    max-width: 1280px

.filterSelect
    width: 200px

.TimePicker .MuiStack-root
    overflow: hidden
.select
  display: flex
  gap: 10px 
@media screen and (min-width: 768px)
    .wrapper
        flex-direction: row
    .filter
        &_mobile
            display: none
        &_desktop
            display: flex
    .subinfo
        flex-direction: column
        justify-content: space-between
        align-items: end

@media screen and (max-width: 1000px)
    .customSelectSetting
        flex-direction: column
        width:150px
        
@media screen and (max-width: 1024px)
    .select
        flex-wrap: wrap
        
