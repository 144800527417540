.participantCard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  text-align: center;
}

.participantCard img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.participantCard h2 {
  font-size: 14px;
  color: #1a2d7d;
  margin: 0;
}

.participantInfo {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.participantInfo p {
  margin: 0;
  font-size: 12px;
}

.row {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.badge {
  background-color: #1a2d7d;
  color: #fff;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 10px;
  display: inline-block;
  margin: 2px;
}

.statusContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  text-align: left;
}

.statusRow {
  display: flex;
  align-items: center;
  gap: 5px;
}

.statusSticker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}

.statusNeopla {
  background-color: #9e9e9e;
}

.statusVzveshen {
  background-color: #4caf50;
}

.statusOpla {
  background-color: #ff9800;
}

.statusText {
  font-size: 14px;
  color: #333;
}

.debtInfo {
  color: #f44336;
  font-size: 14px;
}

.payButton {
  padding: 3px 8px;
  background-color: #f44336;
  color: white;
  font-size: 12px;
  border: 1px solid #f44336;
  border-radius: 5px;
  cursor: pointer;
}

.highlightedBorder {
  border: 1px solid #1a2d7d;
  padding: 3px 6px;
  border-radius: 5px;
  display: inline-block;
}

.disciplineBadges {
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
  justify-content: center;
}

.actionButtons {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 5px;
}

.actionButtons i {
  cursor: pointer;
  font-size: 12px;
  color: #1a2d7d;
}

.actionButtons i:hover {
  color: #143566;
}
