.info
    padding: 16px
    height: 100%
    display: flex
    flex-direction: column
    & > span:last-child
        margin-bottom: 0
    & > span:first-child
        font-size: 18px
        font-weight: bold
    & > span
        margin-bottom: 10px
        line-height: 100%
        display: flex
        align-items: center

.score
    display: flex
    flex-direction: column
    text-align: center
    padding: 4px 8px
    border-radius: 15px
    border: 1px solid #c52c2f
    color: #c52c2f
    font-weight: bold
    margin-top: 8px
    & span
        font-size: 10px
        margin: 0 !important

@media screen and (min-width: 720px)
    .modal
        width: 672px

@media screen and (min-width: 1080px)
    .modal
        width: 900px
