.title
    margin-bottom: 24px

.addComp
    width: 100%
    padding: 16px
    max-width: 600px
    margin: 0 auto

.form
    display: grid
    gap: 16px

.modal
    width: calc(100% - 32px) !important
    max-width: 1600px

.modalDesc 
    display: grid

@media screen and (min-width: 768px)
    .modalDesc
        width: 720px
