.listItem
    display: flex
    flex-direction: column
    &__title
        font-weight: bold
        text-decoration: none
    &__status
        font-size: 12px
        color: gray

a.listItem__title:hover
    color: #0066d1