.informationPage
    padding: 16px
    flex: 1 1 auto
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    background-position: center
    background-repeat: no-repeat
    background-size: cover

.wrapper
    box-shadow: 0 0 500px black !important
    padding: 24px
    margin: 0 auto
    margin-top: 70px
    width: 100%
    max-width: 1080px
    font-size: 18px
    & h2
        display: block
        text-align: center
    & b
        margin-top: 20px
        display: block
        text-align: center
    & a
        color: #fad744
        text-decoration: none

.imgWrapper
    display: flex
    margin: 32px 0
    &:last-child
        margin-bottom: 0
    & img
        width: 50%
        object-fit: cover
        &:first-child
            border-bottom-left-radius: 15px
            border-top-left-radius: 15px
        &:last-child
            border-bottom-right-radius: 15px
            border-top-right-radius: 15px
