.eventInfo * {
  font-family: Arial, sans-serif !important;
  line-height: normal;
}
.eventInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  max-width: 1200px;
  margin: 0 auto;
  p {
    font-size: 20px;
  }
}

/* Основная информация о мероприятии */
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.poster {
  width: 27%;
  height: 340px;
  border-radius: 5px;
  overflow: auto;
  // border: 2px dashed #003366;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.posterPlaceholder {
  font-size: 18px;
  color: #003366;
  text-align: center;
}
.wrapperInfo {
  display: flex;
}
.info {
  width: 40%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 7px;

  h1 {
    font-size: 24px;
    color: #003366;
  }

  .registerBtn,
  .containerBtn {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 200px;
    font-size: 16px;

    &:hover {
      background-color: #cc0000;
    }
  }

  .badges {
    display: flex;
    margin-top: 10px;
    font-size: 14px;
    .badge {
      background-color: #003366;
      color: white;
      padding: 5px 10px;
      border-radius: 12px;
      margin-right: 10px;
    }
  }
}

/* Аккордеон */
.accordion {
  width: 100%;
  margin-top: 20px;

  .accordionItem {
    margin-bottom: 10px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .accordionHeader {
    padding: 10px;
    font-weight: 600;
    background-color: #e7e9eb;
    cursor: pointer;
    color: #34568b;
  }
  .closed {
    text-decoration: line-through;
    color: #999999;
  }

  .accordionContent {
    padding: 10px;
    font-size: 0.95em;
    color: #555;
  }

  &.active .accordionContent {
    height: 400px;
    display: block;
  }
}
.active {
  display: block;
}
.hide {
  display: none;
}
/* Таймер */
.timer {
  font-size: 18px;
  background-color: #003366;
  color: white;
  padding: 6px 10px;
  border-radius: 5px;
  text-align: center;
  width: 165px;
  margin-top: 10px;
}

/* Прогресс бар */
.progressBarsContainer {
  width: 80%;
  max-width: 1200px;
  margin: 20px 0;
}

.progressBar {
  background-color: #e0e0e0;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 20px;

  .progressBarContent {
    background-color: #003366;
    width: 100%;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
}

.progressBarWithWave {
  background-color: #e0e0e0;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  height: 40px;

  .waveContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    overflow: hidden;
    border-radius: 30px;

    .wave {
      background-color: red;
      width: 200%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-50%);
      animation: wave-animation 2s linear;
    }
  }

  .progressBarText {
    position: relative;
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    z-index: 1;
  }
}

/* Описание */
.descriptionContainer {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
}

.description {
  font-size: 18px;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  &.expanded {
    -webkit-line-clamp: unset;
    text-overflow: unset;
  }
}

.readMoreBtn {
  background-color: #003366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: auto;
  margin-top: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #001f4d;
  }
}

@keyframes wave-animation {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.modal {
  width: calc(100% - 32px);
}
.finishButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
}

.organizerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  max-width: 1200px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.logoContainer {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoPlaceholder {
  width: 200px;
  height: 200px;
  border: 2px solid #003366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #003366;
  font-size: 14px;
  text-align: center;
  img {
    width: 60%;
  }
}

.organizerDetails {
  width: 60%;
  text-align: left;
}

.organizerDetails h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.organizerDetails p {
  margin: 5px 0;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.contactInfo a {
  color: #003366;
  text-decoration: none;
  font-size: 18px;
}

.contactInfo a:hover {
  text-decoration: underline;
}

.socialMedia {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.socialMedia a {
  font-size: 28px;
  color: #003366;
  transition: color 0.3s ease, transform 0.3s ease;
}

.socialMedia a:hover {
  color: red;
  transform: scale(1.1);
}

.schedule {
  width: 20%;
  text-align: right;
}

.statusBadge {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 12px;
  text-align: center;
  color: white;
  background-color: green;
  display: inline-block;
  margin-bottom: 10px;
}
.statusBadgeRed {
  background-color: rgb(213, 61, 61);
}
.schedule h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.schedule p {
  margin: 5px 0;
  font-size: 16px;
}
