.events 
    display: flex
    flex-direction: column
    width: 100%
    margin: 0 auto

.loadingButtonWrapper
    padding: 16px
    display: flex
    justify-content: center


.actions
    display: flex
    margin: 0 0 16px auto

.addCode
    display: flex
    flex-direction: column
    align-items: start

.addCodeBody
    margin-top: 20px
    width: 100%