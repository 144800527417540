.footer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-around
    gap: 12px
    width: 100%
    height: 200px
    padding: 40px
    background-color: #1A1F36
    border-top: 2px solid rgba(255,255,255,0.5)
    & span
        color: rgba(255,255,255,0.3)