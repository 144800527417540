.reason
    display: grid
    gap: 12px
    &__buttons
        display: flex
        & > button
            margin-right: 12px

.wrapper
    margin-top: 6px
    display: grid
    gap: 16px
    & *
        user-select: none

.headerWrapper
    display: flex
    justify-content: space-between
    align-items: start
    padding-bottom: 12px
    border-bottom: 1px solid rgba(255, 255, 255, 0.1)

.rounds
    display: flex
    & > div
        min-width: 70px
    & > button
        margin-left: 12px

.basketName
    color: orange
    &_small
        font-size: 12px
    &_medium
        font-size: 14px

.nextFighters
    display: flex
    flex-direction: column
    align-items: end
    margin-left: 20px
    font-weight: bold
    &__title
        font-weight: normal
    &__text
        display: flex
        flex-direction: column
        align-items: end

.countdown
    display: flex
    flex-direction: column
    align-items: center
    &__clock
        font-size: 36px
        font-weight: bold

.scores
    display: flex
    justify-content: space-between
    &__fighter
        width: 50%
        display: flex
        flex-direction: column
        align-items: center
        & > *
            margin-bottom: 8px
    &__setButtonsWrapper
        display: flex
        gap: 8px
        align-items: center
        flex-direction: column
    &__setButtons
        display: grid
        grid-template-columns: repeat(3, 1fr)
        gap: 8px
        & *
            font-weight: bold !important
            font-size: 16px
    &__amount
        font-size: 24px
        font-weight: bold
    &__title
        max-width: 80%
        text-align: center
        display: flex
        align-items: center
        flex-direction: column
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        padding: 16px
        border-radius: 16px
        &_right
            background-color: rgba(0, 0, 255, 0.6)
        &_left
            background-color: rgba(255, 0, 0, 0.6)
    &__buttonsWrapper
        display: flex
        gap: 16px
        &_right
            justify-content: flex-end
        &_left
            justify-content: flex-start

.menu
    display: flex
    flex-direction: column
    align-items: center
    &__timeBtns
        display: grid
        grid-template-columns: repeat(3, 1fr)
        gap: 8px
        margin-bottom: 12px
        border: 1px solid rgba(255,255,255,0.03)
        box-shadow: 0 0 24px 16px rgba(0,0,0,0.1)
        border-radius: 100px
        padding: 4px
    &__confirmBtns
        display: grid
        gap: 8px

.results
    display: flex
    flex-direction: column
    margin-top: 24px
    margin-bottom: 16px
    & *
        font-size: 16px
    &__round
        margin: 0 auto
        text-transform: uppercase
        margin-bottom: 8px
        font-weight: bold
    &__fighter
        &_winner
            font-weight: bold
        &_reason
            font-style: italic
    &__buttons
        display: flex
        & > button:first-child
            margin-right: 12px

.warningsCounter
    display: flex
    flex-direction: column
    color: var(--yellow-color)
    align-items: center
    &__title
        font-size: 12px
    & > button
        margin: 8px 0 8px 0
        border: 1px solid rgba(255,255,255,0.03)
        box-shadow: 0 0 24px 16px rgba(0,0,0,0.1)

.userTitle
    &__club
        font-weight: bold
    &__fio
        display: flex
        flex-direction: column
        font-weight: bold
        font-size: 18px

.fightNumber
    color: #0066d1

.fightId
    color: teal
    font-size: 14px
    margin-left: 8px

.endMessage
    display: grid



@media screen and (min-width: 1232px)
    .modal
        max-width: 1200px
        width: 100%
    .scores
        &__setButtons
            // grid-template-columns: repeat(6, 1fr)

@media screen and (min-width: 1080px) and (max-width: 1231px)
    .modal
        width: calc(100% - 32px)
    .scores
        &__setButtons
            // grid-template-columns: repeat(3, 1fr)

@media screen and (min-width: 768px) and (max-width: 1079px)
    .modal
        width: calc(100% - 32px)
    .scores
        &__setButtons
            // grid-template-columns: repeat(3, 1fr)

@media screen and (min-width: 768px)
    .headerWrapper
        padding-bottom: 0
        border: none
    .countdown
        margin-top: 0
    .userTitle
        &__fio
            flex-direction: row
    .menu 
        display: grid
        // grid-template-columns: repeat(3, 1fr)
        &__timeBtns
            justify-self: center
        &__confirmBtns
            justify-self: end