@use '../../globals'
    
.wrapper
    // max-height: 100%

.list
    max-height: calc(100vh - 200px)
    overflow-y: scroll

.actions
    position: sticky
    bottom: -24px
    right: 0
    padding: 8px
    padding-bottom: 24px
    margin-bottom: -24px
   
    display: flex 
    gap: 16px
    justify-content: end
 