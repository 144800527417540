@use '../../globals'

.wrapper
    position: relative
    display: grid

.categoryWrapper
    overflow-x: auto
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-bottom: 16px

.category
    display: grid
    grid-template-columns: 48px minmax(160px, auto) 1fr
    align-items: center
    width: 100%
    padding: 8px 0
    &:nth-child(n)
        border-bottom: 1px solid rgba(#fff, 0.1)
    &:last-child
        border: none
    &__delete
        justify-self: start

.ageBlock
    padding: 8px 8px 8px 8px
    margin: 8px 0
    margin-right: 24px
    border-radius: 15px
    background-color: globals.$semiBlack
    display: flex
    align-items: center
    justify-content: space-between
    justify-self: start
    &__age
        font-weight: bold
        background-color: globals.$semiBlack
        padding: 8px
        margin-right: 8px
        border-radius: 8px

.weights
    display: grid
    &__set
        display: flex
        gap: 8px
        align-items: center
    &__weightAmount
        width: 20px

button.addButton
    margin: 16px auto 24px auto
    justify-self: center

.actions
    position: sticky
    bottom: -24px
    right: 0
    padding: 8px
    padding-bottom: 24px
    margin-bottom: -24px
   
    display: flex 
    gap: 16px
    justify-content: end
  

.deleteIcon 
    // fill: globals.$primary

.chip
    font-size: 1rem !important
    background-color: globals.$semiBlack !important
    &:hover 
        background-color: black !important