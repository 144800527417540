.docxUpload
    display: flex 
    flex-direction: column
    align-items: flex-start
    gap: 8px
    margin-top: 8px
    margin-bottom: 8px
    
    
.section
    display: flex
    flex-direction: column
    &__title
        margin-bottom: 12px
    &__params
        display: flex
        flex-direction: column
        align-items: flex-end
        & > div 
            width: 100%
            margin-bottom: 8px

.table 
    & td, th
        font-size: 12px
        border: 1px solid rgba(255,255,255,0.3)
        padding: 3px 3px
        color: white
    & th 
        font-weight: bold

.presets
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-top: 40px
    &__grid
        display: grid
        grid-template-columns: 7fr 3fr
        column-gap: 24px
        row-gap: 10px
        & span 
            display: flex
            align-items: end
    &__note
        display: grid
        grid-template-columns: 7fr 3fr
        column-gap: 24px
        margin-bottom: 16px
        & span
            display: flex
            align-items: flex-end
    