.tab
    display: grid
    gap: 16px
    padding: 0 !important
    display: flex
    flex-direction: column

.statusLine
    color: white
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    max-width: 280px

.buttonGroup
    display: grid
    gap: 16px
    grid-template-columns: repeat(2, 1fr)

.wrapper 
    