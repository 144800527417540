.clubs
    padding: 0 16px 16px 16px
    flex: 1 1 auto
    width: 100%
    display: flex
    justify-content: center

.wrapper
    width: 100%
    margin-top: 86px
    display: flex
    flex-direction: column

.loadingButtonWrapper
    padding: 16px
    display: flex
    justify-content: center

.actions
    display: flex
    margin: 0 0 16px auto

@media screen and (min-width: 720px)
    .modal
        width: 672px

@media screen and (min-width: 1080px)
    .modal
        width: 900px
