.content
    display: flex
    flex-direction: column

.wrapper
    & h3
        margin: 0 0 10px 0
        color: #fad744

.summary
    display: flex
    margin-bottom: 36px

.divider
    margin: 16px 0 !important

.info
    margin-left: 16px
    display: flex
    flex-direction: row
    justify-content: space-between
    line-height: 170%
    width: 100%

.infoWrapper
    display: flex
    flex-direction: column

.score
    display: flex
    flex-direction: column
    text-align: center
    padding: 4px 8px
    border-radius: 15px
    border: 1px solid #c52c2f
    color: #c52c2f
    font-weight: bold
    line-height: 120%
    margin-top: 8px
    min-width: 100px
    max-height: 40px
    & span
        font-size: 10px
        margin: 0 !important
        line-height: 110%

.logo
    width: 80px
    height: 80px
    object-fit: cover
    border-radius: 15px

.logoWrapper
    display: flex
    align-items: center
    margin-bottom: 32px

.changePhotoButton
    margin-left: 16px

.titleChange
    color: #fad744
    margin-bottom: 16px

.delButton
    margin-top: 16px !important
    width: 100%

.buttons
    display: flex
    & > div
        margin-right: 8px

.participants
    display: grid
    grid-template-columns: 1fr
    grid-template-areas: "managers managers" "trainers trainers" "sportsmans sportsmans"
    gap: 24px

.managers
    grid-area: managers

.trainers
    grid-area: trainers

.sportsmans
    grid-area: sportsmans

.edit
    display: grid
    margin-top: 16px

.fieldName
    grid-area: name
.fieldCountry
    grid-area: country
.fieldRegion
    grid-area: region
.fieldCity
    grid-area: city
.changeInfoButton
    grid-area: buttonInfo

.changeInfoWrapper
    display: grid
    gap: 16px
    grid-template-areas: 'name name' 'country country' 'region region' 'city city' 'buttonInfo buttonInfo'

@media screen and (min-width: 720px)
    .modal
        width: 672px
    .logo
        width: 100px
        height: 100px
        .changeInfoWrapper
            grid-template-areas: 'name country' 'region city' 'buttonInfo buttonInfo'

@media screen and (min-width: 1080px)
    .modal
        width: 900px
    .participants
        grid-template-columns: repeat(2, 1fr)
        gap: 32px
        grid-template-areas: "managers sportsmans" "trainers sportsmans"
    .logoWrapper
        flex-direction: column
        max-width: 100px
        margin-bottom: 0
    .edit
        grid-template-columns: auto 1fr
        gap: 32px
    .changePhotoButton
        margin: 0
        margin-top: 8px !important
    .titleChange
        margin: 0
    .changeInfoWrapper
        grid-template-areas: 'name country' 'region city' 'buttonInfo buttonInfo'
