.wrapper 
    display: grid
    gap: 16px
.modal
    width: calc(100% - 32px)

.form
    display: grid
    gap: 16px
    &__inputGroup
        margin-top: 24px
        display: grid
        gap: 16px
    &__buttonWrapper
        display: flex
        justify-content: end
        gap: 16px
        align-items: center
        & > span
            font-size: 18px
            font-weight: bold

.info
    font-size: 14px
    font-style: italic

div.weightInput
    min-width: 70px

.idField
  

@media screen and (min-width: 768px)
    .form
        &__inputGroup
            grid-template-columns: repeat(3, 1fr)

@media screen and (min-width: 1400px)
    .form
        &__inputGroup
            display: flex
