.modal {
  display: grid;
  justify-items: center;
  gap: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  padding: 24px;
  form {
    display: grid;
    gap: 16px;
    button:last-child {
      margin-top: 16px;
      width: 100%;
      div {
        width: 100%;
      }
    }
  }

  &__title {
    margin: 0;
    text-align: center;
  }
}

.email {
  text-decoration: none;
  margin: auto;
  cursor: pointer;
  width: 200px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d5dade;
  border-radius: 20px;
  transition: all 1s ease;
  &:hover {
    background: #addbff;
  }
}

@media screen and (min-width: 600px) {
  .modal {
    width: 400px;
  }
}
