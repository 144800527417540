.wrapper
    max-width: 100%
    display: grid
    grid-template-columns: 1fr
    gap: 16px
    & h3
        margin-bottom: 16px

.fightsWrapper
    max-width: 100%
    overflow-x: scroll
.platformsWrapper
    display: flex
    overflow-x: scroll

.title
    margin-bottom: 24px

.filter
    width: 100%
    align-self: start
    &_mobile
    &_desktop
        display: none
        margin-right: 16px
        max-width: 240px

.daysToggle
    width: 100%
    display: flex
    justify-content: start
    margin-bottom: 12px

.platform
    min-width: 360px
    margin-bottom: 16px
    margin-right: 16px
    &__header
        display: flex
        justify-content: space-between
        align-items: center
        color: white
        font-weight: bold
        background-color: rgba(0, 0, 0, 0.5)
        padding: 8px
        margin-bottom: 8px
    &__info
        display: flex
        flex-direction: column
        font-weight: bold
        padding: 8px
        background-color: rgba(0, 0, 0, 0.1)
    &__basket
        font-weight: normal
        text-decoration: underline
        cursor: pointer
        margin-bottom: 10px
        &:hover
            color: #0066d1
    &__button
        display: flex
        justify-content: space-between
        align-items: center
        background-color: rgba(0, 0, 0, 0.2)
        padding: 8px
    &__fight
        margin-bottom: 8px
    &__clock
        display: flex
        justify-content: space-between
        align-items: center

.streamModal
    width: 80%
    height: 80%
    max-width: 800px
    max-height: 500px

@media screen and (min-width: 768px)
    .wrapper
        grid-template-columns: minmax(200px, 240px) auto
    .filter
        &_mobile
            display: none
        &_desktop
            display: flex
