$blockSize: 300px
    
.modal
    width: 100%
    max-width: calc(100% - 32px)

.diplomas 
    display: flex
    flex-direction: column
    gap: 12px
    margin-top: 8px
    margin-bottom: 8px
    &__list
        display: flex
        flex-direction: column
        gap: 8px
        & a 
            color: #0066d1

.diagram
    display: flex
    flex-direction: column
    overflow: scroll
    align-items: start
    gap: 50px
    height: 100%
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2) inset
    margin-top: 16px
   
    & *
        white-space: nowrap
        font-size: 13px
        font-weight: bold
    & hr
        margin: 3px 0
    &__sector
        display: flex
        justify-content: end
        align-items: center
    &__branch
        display: grid
        gap: 50px
    &__origin
        margin-left: 40px
        margin-top: 18px
        min-width: 200px
        text-align: center
        & hr
            width: calc(100% + 40px)
            margin-left: -40px
            display: block 
            height: 1px
            border: 0 
            padding: 0
            border-top: 3px solid #c52c2f
        & div
            display: flex
            flex-direction: column
            align-items: center
            & span:last-child
                color: gray
    &__club
        font-size: 12px
    &__node
        display: flex
        align-items: center
    &__childs
        display: flex
        flex-direction: column
        position: relative
        justify-content: space-around
        align-items: flex-end
        min-height: 200px
    &__title
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 10px
        width: $blockSize
        height: 80px
        padding: 5px
        margin-top: 22px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        & div
            display: flex
            flex-direction: column
            align-items: center
            & span:last-child
                color: gray
    &__borderWrapper
        position: absolute
        height: 100%
        display: flex
        align-items: center
    &__border
        height: 50%
        min-width: $blockSize
        border: 3px solid #c52c2f
        border-left: none

.line
    border-left: thick solid #ff0000
    height: 100%

.resultWrapper 
    position: absolute
    right: 12px

.resultButton
    cursor: pointer

.results
    display: flex
    flex-direction: column
    margin-top: 24px
    margin-bottom: 16px
    & *
        font-size: 16px
    &__round
        margin: 0 auto
        text-transform: uppercase
        margin-bottom: 8px
        font-weight: bold
    &__fighter
        &_winner
            font-weight: bold
        &_reason
            font-style: italic

.adminActions
    display: flex
    gap: 16px

.table 
    width: 100%
    & thead
        background-color: rgba(0,0,0,0.2)
         color: #AF6114
        
    & td, th
        font-size: 16px
        border: 1px solid rgba(255,255,255,0.3)
        padding: 5px 8px
        color: white
    & th 
        font-weight: bold
    &__fight
        font-size: 14px
        color: #0066d1
    &__score
        color: gold


