.events
    margin-top: 16px
    display: grid
    grid-template-columns: repeat(1, 1fr)
    gap: 16px
    &__item
        display: flex
        flex-direction: column
        align-items: start
        align-self: flex-start
        border: 1px solid rgba(255,255,255,0.03)
        box-shadow: 0 0 24px 16px rgba(0,0,0,0.1)
        padding: 12px


.link
    color: #0066d1
    cursor: pointer
    font-weight: bold
    margin-bottom: 12px
    &:hover
        color: rgba(#0066d1, 0.5)


@media screen and (min-width: 768px)
    .events
        grid-template-columns: repeat(2, 1fr)