
.modal
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: calc(100% - 32px)
    padding: 16px
    outline: none
    max-height: calc(100vh - 64px)
    overflow-y: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    &__title
        margin: 0
        text-align: center
    &::-webkit-scrollbar
        display: none

.title
    display: flex
    justify-content: space-between
    align-items: start
.closeButton 
    margin-top: -4px

@media screen and (min-width: 600px)
    .modal
        max-height: calc(100vh - 32px)
        width: 400px
        padding: 24px
