.wrapper 
    display: grid
    gap: 16px
    
.statusLine
    color: white
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    max-width: 280px

.buttonsBlock
    display: grid
    gap: 16px
    grid-template-columns: 1fr 1fr
