.sidebar {
  height: 100vh;
  background-color: #1f3b4d;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10vh;
  padding: 10px;
  width: 180px;
  transition: width 0.3s ease;
}

.collapsed {
  width: 50px;
  padding: 10px 5px;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.logo-icon {
  width: 30px;
  height: 30px;
  background-color: #ff0000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
  font-size: 18px;
  margin-right: 5px;
  transition: background-color 0.3s ease;
  flex-shrink: 0;
}

.logo-text {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  transition: color 0.3s ease;
}

.sidebar.collapsed .logo {
  justify-content: center;
}

.sidebar.collapsed .logo-text {
  display: none;
}

.profile {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;

  cursor: pointer;
}

.profile-img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: #295f8a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  transition: background-color 0.3s ease;
  img {
    width: 100%;
  }
}

.profile-img i {
  font-size: 20px;
  color: #fff;
}

.sidebar.collapsed .profile-img {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-size: 14px;
  transition: color 0.3s ease;
  margin-left: 10px;
}

.profile-info span:first-child {
  font-weight: bold;
}

.profile-info span:last-child {
  font-size: 12px;
  color: #ffffff;
}

.profile-text {
  margin-top: 2px;
  font-size: 12px;
  color: #ffffff;
}

.sidebar.collapsed .profile-info {
  display: none;
}

.divider {
  height: 1px;
  background-color: #295f8a;
  margin: 10px 0;
  position: relative;
  transition: background-color 0.3s ease;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 8px 8px;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  z-index: 1;
}

.menu-item:hover,
.menu-item.active {
  background-color: #295f8a;
  color: #ffffff;
}

.icon {
  font-size: 16px;
  width: 30px;
  text-align: center;
  transition: color 0.3s ease;
  flex-shrink: 0;
}

.sidebar.collapsed .icon {
  display: block;
}

.sidebar.collapsed .text {
  display: none;
}

.sub-menu {
  display: none;
}

.menu-item.rating:hover + .sub-menu,
.sub-menu:hover {
  display: block;
}

.sub-menu {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.sub-menu .menu-item {
  padding-left: 0;
}
.arrow {
  margin-left: auto;
  font-size: 10px;
  transition: transform 0.3s ease;
}

.menu-item.rating:hover .arrow {
  transform: rotate(180deg);
}

.sidebar.collapsed .menu-item.rating .arrow {
  display: none;
}

.menu-item[title]::after {
  content: attr(title);
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%) translateX(10px);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.menu-item:hover::after {
  opacity: 1;
  transform: translateY(-50%) translateX(0);
}

.toggle-button {
  width: 20px;
  height: 20px;
  background-color: #1f3b4d;
  border: 2px solid #295f8a;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: -10px;
  right: -15px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.toggle-button i {
  transition: transform 0.3s ease;
}

.sidebar.collapsed .toggle-button i {
  transform: rotate(180deg);
}

.toggle-button:hover {
  background-color: #999;
}

.language-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 6px;
  background-color: #1f3b4d;
  border: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 15px);
  text-align: center;
  transition: background-color 0.3s ease;
}

.language-switcher:hover {
  background-color: #295f8a;
}

.sidebar.collapsed .language-switcher .text {
  display: none;
}

.main-content {
  margin-left: 300px; /* 1.5x увеличение ширины меню */
  padding: 20px;
  transition: margin-left 0.3s ease, width 0.3s ease;
  width: calc(100% - 300px); /* Карточка остается того же размера */
}

.sidebar.collapsed ~ .main-content {
  margin-left: 40px;
  width: calc(100% - 40px);
}

/* Стиль для карточки */
.card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  width: 100%; /* Карточка остается того же размера при изменении меню */
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h2 {
  margin-top: 0;
}

.card p {
  line-height: 1.6;
}

.card p a {
  color: #ff0000; /* Ссылка красного цвета */
  text-decoration: none;
}

.card p a:hover {
  color: #ff0000; /* Ссылка красного цвета при наведении */
  text-decoration: underline;
}

.button {
  display: inline-block;
  background-color: #295f8a;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #12548a;
  color: #ffffff; /* Цвет остается белым при наведении */
  text-decoration: none; /* Убираем подчеркивание при наведении */
}
.email {
  text-decoration: none;
  color: #ffffff;
  position: relative;
  bottom: 50px;
}
