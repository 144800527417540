
.fileInput
    display: none

    
.filesList
    display: flex
    flex-direction: column
    gap: 8px
    &__item
        display: flex
        justify-content: space-between
        align-items: center
        & span
            width: 270px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
