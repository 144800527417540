.userPage
    padding: 16px
    flex: 1 1 auto
    width: 100%
    display: flex
    justify-content: center

.wrapper
    display: grid
    margin: 0 auto
    margin-top: 70px
    width: 100%
    max-width: 900px
    grid-template-areas: "avatar avatar" "info info" "about about" "manage manage" "other other"
    gap: 16px
    &_guest
        grid-template-areas: "avatar avatar" "info info" "about about" "other other"
    &__avatar
        grid-area: avatar
    &__manage
        grid-area: manage
    &__info
        grid-area: info
    &__about
        grid-area: about
    &__other
        grid-area: other

.additional
    padding: 16px

.title
    color: var(--blue-color)

.link
    color: #0066d1
    cursor: pointer
    font-weight: bold
    margin-bottom: 12px
    &:hover
        color: rgba(#0066d1, 0.5)

.body
    padding: 16px
    height: 100%
    display: flex
    flex-direction: column
    & h3
        color: var(--blue-color)


.futureEvents
    margin-top: 16px
    display: grid
    grid-template-columns: repeat(1, 1fr)
    gap: 16px
    &__item
        display: flex
        flex-direction: column
        align-items: start
        align-self: flex-start
        border: 1px solid rgba(255,255,255,0.03)
        box-shadow: 0 0 24px 16px rgba(0,0,0,0.1)
        padding: 12px

.payment
    margin-top: 4px
    align-self: flex-end
    &__debt
        font-size: 12px
        margin-right: 16px
        height: 36.5px


.statusDot
    display: inline-block
    min-width: 12px
    min-height: 12px
    border-radius: 50%
    &_red
        background-color: red
    &_yellow
        background-color: yellow
    &_green
        background-color: green
    &_gray
        background-color: gray

.history
    display: flex
    flex-direction: column

.updateDocs
    &__text
        white-space: pre-line


@media screen and (min-width: 600px)
    .userPage
        padding: 24px

@media screen and (min-width: 768px)
    .wrapper
        grid-template-areas: "avatar info about" "manage manage manage" "other other other"
        grid-template-columns: auto 1fr 1fr
        &_guest
            grid-template-areas: "avatar info about" "other other other"
    .futureEvents
        grid-template-columns: repeat(2, 1fr)
