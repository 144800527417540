.balanceLine
    display: flex
    align-items: center
    height: 40px
    margin-right: 8px
    cursor: pointer
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif

.wrapper 
    margin-top: 16px
    display: grid
    gap: 16px

.linearProgress
    width: 24px
    margin: 0 4px 0 8px