.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.datagrid-cell_disable-border {
  border: none !important;
}
.datagrid-row_set-border {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.datagrid-row_set-border:last-child {
  border: none;
}

div.MuiAlert-standardInfo {
  background-color: rgba(0, 141, 197, 0.631);
}
div.MuiAlert-standardSuccess {
  background-color: rgba(0, 255, 13, 0.3);
}
div.MuiAlert-standardError {
  background-color: rgba(255, 4, 0, 0.3);
}
div.MuiAlert-standardWarning {
  background-color: rgba(255, 150, 0, 0.3);
}
button.MuiIconButton-root.Mui-disabled > svg {
  fill: rgba(0, 0, 0, 0.2);
}
* {
  font-family: Jura, Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

#notistack-snackbar {
  white-space: pre-wrap;
}
