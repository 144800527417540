@use '../../globals'

.wrapper
    max-width: 100% !important
    margin: 0 auto

.modal
    width: calc(100% - 32px)
    max-width: 1200px

.title
    margin-bottom: 16px

.disciplines
    display: flex
    flex-direction: column
    gap: 10px
    align-items: start
    &__item
        width: 100%
        display: flex
        justify-content: space-between
        border-radius: 15px
        transition: all 0.1s
        &:hover
            background-color: rgba(globals.$secondary, 0.1)
    &__itemWrapper
        display: flex
        align-items: center

.discipline
    display: flex
    gap: 12px
    &__name
        display: inline-flex
        align-items: center
        &_faded
            color: rgba(#fff, 0.5)

.createDiscipline
    display: flex
    gap: 16px
    flex-direction: column
    align-items: end
    margin-top: 16px
    width: 100%
    &__fields
        width: 100%
        display: grid
        grid-template-columns: 80fr 40fr
        grid-template-rows: 1fr 1fr
        gap: 16px

@media screen and (min-width: 1080px)
    .modal
        width: 1000px
