.title {
  margin-bottom: 16px;
}

.master {
  font-weight: bold &__name {
    color: #0066d1;
    text-align: center;
  }
}

.table {
  &__head * {
    font-weight: bold;
    color: #fad744;
  }

  &__age {
    white-space: nowrap;
  }
}
.tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 2px solid #ccc;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  overflow-x: auto;
}

.tab {
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #555;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-bottom 0.3s;
  white-space: nowrap;
}

.tab:hover {
  color: #6e8efb;
}

.tab.active {
  color: #6e8efb;
  border-bottom: 2px solid #6e8efb;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-label {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}

.toggle14 input {
  display: none;
}

.toggle14 label {
  display: inline-block;
  width: 60px;
  height: 28px;
  background-color: #eee;
  border-radius: 14px;
  position: relative;
  cursor: pointer;
}

.toggle14 label:before,
.toggle14 label:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
  color: #333;
  z-index: 2;
}

.toggle14 label:before {
  content: "М";
  left: 10px;
}

.toggle14 label:after {
  content: "Ж";
  right: 9px;
}

.toggle14 label span {
  display: block;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.toggle14 input:checked + label span {
  transform: translateX(32px);
}

.category-grid {
  display: flex;

  gap: 15px;
  font-size: 14px;
  margin-top: 20px;
}

.category {
  background: linear-gradient(135deg, #1f4585, #7a7f8d);
  // background: linear-gradient(135deg, #7a7f8d, #1f4585);
  // background: linear-gradient(135deg, #c0bebe, #1171af);
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}
// .category_red {
//   background: linear-gradient(135deg, #606163, #dc3f3f);
//   // background: linear-gradient(135deg, #5e5f60, #980606);
// }
.category:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.category h2 {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.level {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
}

.description {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #fff; /* Цвет текста без дополнительных стилей */
}

.weights {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center; /* Центровка весов */
  margin-top: 10px;
}

.weights div {
  flex: 0 1 auto;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border-radius: 6px;
  text-align: center;
  padding: 5px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.weights div:hover {
  background-color: #ffffff;
  color: #6e8efb;
  cursor: pointer;
}

@media (max-width: 768px) {
  .tabs {
    overflow-x: auto; /* Горизонтальная прокрутка для мобильных устройств */
    white-space: nowrap;
  }
  .category-grid {
    grid-template-columns: 1fr;
  }
  .weights {
    justify-content: center; /* Центровка весов в мобильной версии */
    flex-wrap: wrap;
  }
  .weights div {
    margin-bottom: 4px;
  }
}

.row {
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: 5px;
}
