.separation
    width: 100%
    display: flex
    flex-direction: column
    align-items: end

    
.grid
    width: 100%
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 8px
    align-items: center
    margin-bottom: 8px