.transfer
    display: grid
    gap: 20px
    & h4 
        margin-top: 25px
    &__users
        display: flex
        flex-direction: column
        height: 200px
        overflow-y: scroll
        scrollbar-color: dark