.manage
    padding: 16px
    display: grid
    gap: 16px
    
    &__buttons
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: 36px 36px
        gap: 16px
        & button
            width: 100%
.extraFunctions
    display: grid
    gap: 16px
    & button
        font-size: 10px
        max-height: 56px
    &__search
        display: grid
        gap: 16px
        grid-template-columns: 1fr 30%

.requestButton
    width: 100%
    height: 100%
.divider
    display: none

.delButton
    width: 100%

@media screen and (min-width: 768px)
    .manage
        grid-template-columns: 40fr 1fr 60fr
        &__buttons
        &__search
            & button
                font-size: 12px
    .divider
        display: block
