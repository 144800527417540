.baskets
    display: grid
    grid-template-columns: repeat(1, 1fr)
    gap: 16px
    max-width: 1600px
    margin-top: 16px

.resultsWrapper
    display: flex
    flex-direction: column
    align-items: start

.results
    display: flex
    flex-direction: column
    max-width: 100%

.filter
    width: 100%
    &_mobile
    &_desktop
        display: none
        margin-right: 16px
        max-width: 240px

.basket
    display: flex
    flex-direction: column
    border: 1px solid rgba(255,255,255,0.03)
    box-shadow: 0 0 24px 16px rgba(0,0,0,0.1)
    padding: 12px
    &__title
        color: black
        cursor: pointer
        font-weight: bold
        margin-bottom: 12px
        &:hover
            color: rgba(#0066d1, 0.5)

.table 
    width: 100%
    
    & thead
        background-color: rgba(0,0,0,0.2)
        & th
              border: 1px solid black
              background: white
    &_colorful tbody
        & td
           border: 1px solid black
         
        & tr:nth-child(1) *
            background: gold
        & tr:nth-child(2) *
            background: silver
        & tr:nth-child(3) *
            background: #AF6114
        
    & td, th
        font-size: 16px
        border: 1px solid rgba(255,255,255,0.3)
        padding: 5px 8px
        color: black
    & th 
        font-weight: bold

.diplomaDownload
    display: inline-block
    height: 0

.diploma
    cursor: pointer
    display: flex
    justify-content: space-between
    width: 100%

@media screen and (min-width: 768px)
    .baskets
        grid-template-columns: repeat(2, 1fr)
    .resultsWrapper
        flex-direction: row
    .filter
        &_mobile
            display: none
        &_desktop
            display: flex

@media screen and (min-width: 1080px)
    .baskets
        grid-template-columns: repeat(3, 1fr)
