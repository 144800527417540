.gridBlock
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr
    justify-content: center
    gap: 16px

.gridElem
    max-width: 100%

.card
    height: 100%

.cardAction
    max-height: 220px
    width: 100%
    height: 100%
    display: flex !important
 


.cardContentWrapper
    width: 100%
    height: 100%
  
 

.titleCard
    margin: 0
    margin-bottom: 5px
    text-align: left
    font-size: 14px
.address
    font-size: 12px
.contentCard
    display: flex
    flex-direction: column
    text-align: left
    gap: 8px
    font-size: 12px

.img
    height: 120px
    max-width: 120px !important
    margin-left: 10px 
    border-radius: 6px
    object-fit: fill

   
.icons
      display: flex
      align-items: center
      gap: 5px


.loadingButtonWrapper
    display: flex
    justify-content: center
    width: 100%
    margin-top: 56px
    color: black

.gridWrapper
    width: 100%

.date
    color: var(--yellow-color)
.participants
     display: flex
     align-items: center
     gap: 10px
   
.timer
    display: flex
    flex-direction: column
    width: 150px
    background: #e0e0e0
    color: #555
    border-radius: 5px
    padding: 2px
    align-items: center
    justify-content: center
    font-size: 14px
    & span
        margin-bottom: 8px
    & b
        font-size: 12px
        text-align: center
.timer_closed
    background: gray
    color: white

@media screen and (min-width: 1200px)
    .gridBlock
        grid-template-columns: 1fr 1fr 1fr 
   
@media screen and (max-width: 600px)
    .img
        display: none !important