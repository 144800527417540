.wrapper
    display: flex
    align-items: center

span.uploadButton
    width: 42px
    min-width: 42px

.input
    font-size: 16px
    margin-left: 10px
    color: white
    opacity: 0
    width: 0
    &::file-selector-button
        display: none
    &::-webkit-file-upload-button
        display: none
    &::-ms-browse
        display: none
