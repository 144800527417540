.login
    margin: 0 auto
    max-width: 360px
    
.wrapper
    padding: 16px

.page
    flex: 1 1 auto
    margin: 90px 24px 16px 24px
    
.form
    display: grid
    gap: 16px
    & > *
        width: 100%