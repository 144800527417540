.userEditPage
    padding: 16px
    flex: 1 1 auto
    width: 100%
    display: flex
    justify-content: center

.wrapper
    display: grid
    gap: 16px
    margin: 0 auto
    margin-top: 70px
    width: 100%
    max-width: 100%

.form 
    display: flex
    flex-direction: column
.grid
    display: grid
    gap: 16px
    margin-bottom: 16px

.infoSection
    padding: 16px


.avatar
    padding: 16px
    display: flex
    flex-direction: row
    gap: 16px
   

.section 
    display: flex
    flex-direction: column
    &__password 
        width: 100%
        margin-top: 16px
        & form
            width: 100%
            padding: 16px
            display: flex 
            flex-direction: column
            justify-content: start
            & > div
                margin-bottom: 16px
            & > div:last-child
                margin-bottom: 0

.phone
    display: grid
    grid-template-columns: 20fr 80fr
    gap: 8px

@media screen and (min-width: 600px)
    .wrapper 
        max-width: 720px
    .section 
        flex-direction: row
        &__password
            margin-top: 0
            margin-left: 16px
    .grid 
        grid-template-columns: 1fr 1fr
    .avatar
        flex-direction: column
        margin: 0 auto
   