@use '../../globals'

.tableRow th
    border-right: 1px solid rgba(255, 255, 255, 0.1)
    max-width: 800px !important
    &:last-child
        border-right: none !important

.structure
    width: 100%

.filter
    &_mobile
        margin-bottom: 16px
    &_desktop
        display: none
        margin-right: 16px
        min-width: 240px

.counts
    display: flex
    flex-direction: column
    font-size: 14px
    margin-top: 8px
    &__number
        color: var(--yellow-color)
        font-size: 14px
        font-weight: bold

.manage
    display: flex
    flex-direction: column
    gap: 8px

.wrapper
    display: flex
    flex-direction: column

.loadingButtonWrapper
    padding: 16px
    display: flex
    justify-content: center

.actions
    display: flex
    margin: 0 0 16px auto

.statusDot
    display: inline-block
    min-width: 12px
    min-height: 12px
    border-radius: 50%
    &_red
        background-color: red
    &_yellow
        background-color: var(--yellow-color)
    &_green
        background-color: green
    &_gray
        background-color: gray

.statuses
    display: flex
    flex-direction: column
    color: black
    & > div
        margin-right: 24px

.navToUser
    text-decoration: none
    color: black
    font-weight: bold
    &:hover
        color: var(--blue-color)

.centered
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    &_left
        justify-content: start

.finishButtons
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 16px
    margin-top: 16px

.editUser
    display: grid
    gap: 16px
    &__disciplines
        display: grid
        gap: 4px

.masterCell
    font-size: 12px

.debtText
    font-size: 12px
    white-space: nowrap

@media screen and (min-width: 600px)
    .manage
        flex-direction: row
    .statuses
        flex-direction: row
    .structure
        display: grid
        gap: 16px
        grid-template-columns: 240px calc(100% - 256px)
    .filter
        &_mobile
            display: none
        &_desktop
            display: flex
            align-self: start
