.buttonsWrapper
    display: flex
    margin: auto 0 auto auto

.buttonMenu
    display: flex

.menu
    display: flex

.closeButton
    justify-content: flex-end !important

.menuInline
    display: none

@media screen and (min-width: 768px)
    .buttonsWrapper
        margin: 0

    .buttonMenu
        display: none !important

    .menuInline
        display: flex
        margin: 0 auto
    
    .menu
        display: none