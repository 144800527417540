/* Carousel.module.css */
.carouselContainer {
  margin: 20px 0;
  text-align: center;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.title {
  font-size: 24px;
  color: #003366;
  margin: 20px 0;
  text-align: center;
}
.carouselWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.arrow {
  cursor: pointer;
  font-size: 48px;
  color: #003366;
  user-select: none;
  padding: 0 10px;
  transition: color 0.3s ease;
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.leftArrow {
  left: 10px;
}

.rightArrow {
  right: 10px;
}

.arrow:hover {
  color: red;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoPlaceholder {
  width: 100px;
  height: 100px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.logoPlaceholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.itemName {
  margin-top: 10px;
  font-size: 16px;
  color: #003366;
}

.participants {
  font-size: 14px;
  color: #666666;
}
